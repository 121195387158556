import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[appLazyLoad]',
  standalone: true
})
export class LazyLoadDirective implements AfterViewInit {

  @Input('appLazyLoad') lazyImage!: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private sanitizer: DomSanitizer) { }

  ngAfterViewInit() {
    if (typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const element = this.el.nativeElement as HTMLImageElement | HTMLIFrameElement;
            if (this.lazyImage) {
              this.renderer.setAttribute(this.el.nativeElement, 'src', this.lazyImage as string);
            }
            // this.renderer.setAttribute(element, 'src', this.lazyImage);

            observer.unobserve(element);
          }
        });
      });

      observer.observe(this.el.nativeElement);
    } else {
      const element = this.el.nativeElement as HTMLImageElement | HTMLIFrameElement;
      this.renderer.setAttribute(element, 'src', this.lazyImage);
    }
  }
}
